import {
  CAMBRIDGE_WAREHOUSE,
  // COLISSIMO_METHOD, CORREOS_METHOD,
  // DHL_PAKET_METHOD,
  // DHL_WARENPOST_METHOD,
  // DPD_SHIPPING_COMPANY, DPD_VAT_METHOD,
  FBM_ORDER_LABEL,
  FBM_ORDER_TYPES,
  FBM_SHIPPING_TYPES,
  FBM_STATUSES,
  // GSL_METHOD,
  LODZ_WAREHOUSE,
  // POST_AT_METHOD,
  // POST_NL_VAT_METHOD,
  // POSTNL_SHIPPING_COMPANY,
  ROYAL_MAIL_COMPANY, //ROYAL_MAIL_GLOBAL_COMPANY,
  USA_COUNTRY_ID,
} from "../../staticData/staticVariables";
import {FBMMixinsDeliveryData} from "./FBMMixinsDeliveryData";


export {FBMMixinsDeliveryData}


export const FBMMixinsHelper = {
  computed: {

    isEngraving() {
      return this.FBM.data.isEngraving
    },

    isWarehouseLodz() {
      return this.FBM.data.Order.data.warehouse?.id === LODZ_WAREHOUSE.storage_id
    },
    isWarehouseCambridge() {
      return this.FBM.data.Order.data.warehouse?.id === CAMBRIDGE_WAREHOUSE.storage_id
    },

    isConsolidation() {
      return this.FBM.data.Order.getType()?.id === FBM_ORDER_TYPES.FBM_ORDER_CONSOLIDATION.id
    },

    isLabel() {
      return this.FBM.data.Order.getType()?.id === FBM_ORDER_TYPES.FBM_ORDER_LABEL.id
    },

    isWarehouse() {
      return this.FBM.data.Order.getType()?.id === FBM_ORDER_TYPES.FBM_ORDER_WAREHOUSE.id
    },

    isEasyType() {
      return this.FBM.data.easyOrderType
    },

    calculatePrice() {
      return this.$options.filters.number(this.FBM.getTotalSumProformProduct(), '0.00')
    },

    checkSelectedUSACountry(){
      return this.FBM.getTrsnAddressCountry().id === USA_COUNTRY_ID
    },

    getShippingMethodsVariable(){
      let orderLabel = this.FBM.data.Order.getType()?.id === FBM_ORDER_LABEL.id
      let methodsVariable = 'shipping_methods'
      if(orderLabel){
        methodsVariable = 'shipping_price_variant'
      }

      return {orderLabelType: orderLabel, methodsVariable : methodsVariable}
    },

    getShippingPartner(){
      return this.FBM.data.shippingPartner
    },

    checkIsMarketplaceCompany() {
      // return this.FBM.data.shippingPartner.id === DPD_SHIPPING_COMPANY.id ||
      //   this.FBM.data.shippingPartner.id === POSTNL_SHIPPING_COMPANY.id ||
      //   this.FBM.data.shippingPartner.id === POST_AT_METHOD.id ||
      //   this.FBM.data.shippingPartner.id === POST_NL_VAT_METHOD.id ||
      //   this.FBM.data.shippingPartner.id === COLISSIMO_METHOD.id ||
      //   this.FBM.data.shippingPartner.id === DHL_WARENPOST_METHOD.id ||
      //   this.FBM.data.shippingPartner.id === DHL_PAKET_METHOD.id ||
      //   this.FBM.data.shippingPartner.id === GSL_METHOD.id ||
      //   this.FBM.data.shippingPartner.id === CORREOS_METHOD.id ||
      //   this.FBM.data.shippingPartner.id === DPD_VAT_METHOD.id

    // || this.FBM.data.shippingPartner.id === ROYAL_MAIL_GLOBAL_COMPANY.id
      if(this.FBM.data.shippingPartner.id === ROYAL_MAIL_COMPANY.id) return true

      return false
    },

    checkIsRequiredVatNumber() {
      if(this.FBM.data.shippingPartner.id === ROYAL_MAIL_COMPANY.id) return true

      return this.FBM.data.shippingPartner?.need_paid_vat
    },

    globalCompany() {
      if(this.FBM.data.shippingPartnerType.delivery_service_code === 'g24') return true

      return false
    },

  },

  methods: {
    getShipById(id) {
      switch (id) {
        case FBM_SHIPPING_TYPES.FBM_SHIPPING_FEDEX.id:
          return FBM_SHIPPING_TYPES.FBM_SHIPPING_FEDEX

        case FBM_SHIPPING_TYPES.FBM_SHIPPING_USPS.id:
          return FBM_SHIPPING_TYPES.FBM_SHIPPING_USPS

        case FBM_SHIPPING_TYPES.FBM_SHIPPING_LANDMARK.id:
          return FBM_SHIPPING_TYPES.FBM_SHIPPING_LANDMARK

        case FBM_SHIPPING_TYPES.FBM_SHIPPING_APC.id:
          return FBM_SHIPPING_TYPES.FBM_SHIPPING_APC

        case FBM_SHIPPING_TYPES.FBM_SHIPPING_BP.id:
          return FBM_SHIPPING_TYPES.FBM_SHIPPING_BP

        case FBM_SHIPPING_TYPES.FBM_SHIPPING_NP.id:
          return FBM_SHIPPING_TYPES.FBM_SHIPPING_NP
      }
    },

    getOrderFBMTypeById(id) {
      return this._.find(FBM_ORDER_TYPES, {id: id})
    },


    isStatusComplete(status){
      return FBM_STATUSES.FBM_COMPLETED_STATUS.value === status
    },

    isStatusStop(status){
      return FBM_STATUSES.FBM_STOP_STATUS.value === status
    },

    isStatusInProgress(status){
      return FBM_STATUSES.FBM_IN_PROGRESS_STATUS.value === status
    },

    isGlobal24Table(item) {
      return item?.delivery_method?.shipping_company?.delivery_service?.code === 'g24'
    },

  }
}


export const FBMProductMixin = {

  computed: {
    isUseCustomizationAndEdit() {
      return this.isEdit &&
        (
          this.FBM.data.isEngraving ||
          this.FBM.data.Embroidery.data.isEmbroidery ||
          this.FBM.data.TShirtPrinting.data.isTShirtPrinting ||
          this.FBM.data.LeatherStamp.data.isLeatherStamp ||
          this.FBM.data.PhotoPrint.data.isPhotoPrint ||
          this.FBM.data.UVPrinting.data.isUVPrinting
        )
    },
  },

  methods: {
    isDisabledCustomization(Product) {
      return Product.product?.productObject?.service_product || false
    },

    changeWarehouse(val) {
      this.FBM.data.Order.setWarehouse(val)
      this.getProductsFBMByUser().then(() => {
        this.productsForCellArray.map((item, index) => {
          let product = this._.find(this.initialOptionsProducts, {id: item.data.Product.product.productObject?.id})
          if(product) {
            this.productsForCellArray[index].data.Product.setProductObject(product)
          }
        })
      })
    },

    getWarehouseStorage(withoutProducts = false) {
      if(!this.FBM.getTrsnAddressCountry()?.id) return

      let data = {
        recipient_country_id: this.FBM.getTrsnAddressCountry().id,
        recipient_zip_code: this.FBM.getTrsnZip(),
        order_type: this.FBM.data.Order.getType()?.value
      }

      if (this.FBM.getIdReturned()) {
        data["return_parcel_id"] = +this.FBM.getIdReturned()
      }

      if(this.isAdmin) {
        data["user_id"] = this.FBM.data.User.userId
      }

      this.$store.dispatch('fetchWarehouseStorageByCountry', data).then((response) => {
        this.warehouses = this.getRespData(response)

        if(this.warehouses.length === 0) return

        // if(this.isConsolidation) {
        //   this.warehouses = [this._.find(data, {id: BLAINE_WAREHOUSE.storage_id})]
        // } else {
        //   this.warehouses = data
        // }

        // if not selected warehouse
        if(!this.FBM.data.Order.data.warehouse) {
          this.FBM.data.Order.setWarehouse(this._.first(this.warehouses))
        }

        // if selected warehouse not found in received warehouses
        if(!this._.find(this.warehouses, {id: this.FBM.data.Order.data.warehouse.id})) {
          this.FBM.data.Order.setWarehouse(this._.first(this.warehouses))
        }

        if(withoutProducts) return

        this.getProductsFBMByUser()
      })
    },

    async getProductsFBMByUser() {
      this.productLoading = true
      if(!this.user.userId) return

      const query = new this.Query();
      let url = '?';
      let myQuery = query
        .for('posts')

      if (this.isAdmin) {
        myQuery.where('ProductUserId', parseInt(this.user.userId))
      }

      console.log(this.FBM.data.Order.data.warehouse?.id);
      myQuery.where('byStorageId', this.FBM.data.Order.data.warehouse?.id)

      myQuery.sort(`ProductName`)

      myQuery.where('ProductInDraft', '0')

      url = url + myQuery.limit(100000).page(parseInt(0)).url().split('?')[1]

      let typeFetch = 'fetchProducts'

      /**
       * If order FBM by resend return product
       */
      if(this.FBM.getIdReturned()) {
        typeFetch = 'fetchProductsFBMReturns'
        url = this.FBM.getIdReturned()
      }

      return this.$store.dispatch(typeFetch, url).then((response) => {
        this.initialOptionsProducts = this.getRespPaginateData(response)
        this.productLoading = false
      })
    },

    changeProductName(item, val) {
      item.setProduct(val)

      if(!val) return

      // SET item_quantity AND item_price from Proform product data
      this.$store.dispatch('getProducts', val.id + '?storageId=' + this.FBM.data.Order.data.warehouse?.id).then((response)=>{
        let data = this.getRespData(response)
        if(data.proformData !== []){
          console.log('has proform, set quantity and price');
          // item.setProductCount(data?.proformData?.proformOptions?.item_quantity || 0)
          item.setProductCount(1)
          item.setProductPrice(data?.proformData?.proformOptions?.item_price || 0)
        } else {
          item.setProductCount(0)
          item.setProductPrice(0)
        }

        if (data.proformData !== [] && data?.count_in_storage?.count_normal <= 0 && (this.isWarehouse || this.isConsolidation)) {
          this.openNotify('warn', 'fbm_ProductMissingWarning')
        }
      }).catch(error => this.createErrorLog(error))
    },

    changeProductQuantity(item, val) {
      console.log(item, val);
      item.setProductCount(val)
    },

    removeProduct(products, index) {
      this.FBM.removeProductForCells(products, index)

      // if (item.product.productModelId !== '') {
      //   this.$store.dispatch('removeFbmProduct', {
      //     id: this.FBM.data.Order.getId(),
      //     data: {id: item.product.productModelId}
      //   })
      //     .then((response) => {
      //       if (!this.getResponseStatus(response)) return this.openNotify('error', 'common_notificationUndefinedError')
      //
      //       switch (this.getResponseStatus(response)) {
      //         /**
      //          * Success
      //          */
      //         case this.$store.getters.GET_ERRORS.SUCCESS_CODE: {
      //           // this.openNotify('success', 'common_notificationRecordChanged')
      //           this.FBM.removeProduct(index)
      //           break
      //         }
      //         /**
      //          * Validation Error
      //          */
      //         case this.$store.getters.GET_ERRORS.VALIDATION_ERROR_CODE: {
      //           let errors = response.response.data.errors;
      //           this.notifyErrorHelper(errors)
      //           break
      //         }
      //         /**
      //          * Undefined Error
      //          */
      //         default: {
      //           this.openNotify('error', 'common_notificationUndefinedError')
      //         }
      //       }
      //     })
      // }
    },
  }


}


export const FBMMixins = {
  mixins: [FBMMixinsHelper],

  computed: {

    getLabelBarcode() {
      return this.FBM.data.Order.data.orderOBJ?.orderFbmInstance?.barcode_label_file
    },

    getLabelPrice() {
      let data = {
        total_price: 0,
      }

      if(!this.FBM.data.isUserLabel) {
        data.price = this.FBM.data.price
        data.total_price += this.FBM.data.price
      }

      if(this.FBM.data.insurancePrice > 0) {
        data.insurancePrice = this.FBM.data.insurancePrice
        data.total_price += Number(this.FBM.data.insurancePrice)
      }
      if(this.FBM.data.giftPaper?.price) {
        data.giftMassage = this.FBM.data.giftPaper.price
        data.total_price += Number(this.FBM.data.giftPaper.price)
      }
      if(this.FBM.data.insuranceFromDeliveryCompany !== '0' && !this.FBM.data.isUserLabel && !(this.isUserHasEasyOrder && this.isConsolidation)){
        data.insuranceFromDeliveryCompany = this.FBM.data.insuranceFromDeliveryCompany
        data.total_price += Number(this.FBM.data.insuranceFromDeliveryCompany)
      }
      if(this.getUserOrderProcessFee && !this.getUserFBMAutodetectFee && !(this.isUserHasEasyOrder && this.isConsolidation)){
        data.orderProcessFee = this.calculateOrderFee?.cost || 0 //this.getUserOrderProcessFee
        data.total_price += Number(this.calculateOrderFee?.cost || 0)
      }
      if(this.getUserFBMAutodetectFee && this.FBM.data.calcOrderProcessFee?.cost > 0 && !(this.isUserHasEasyOrder && this.isConsolidation)) {
        data.orderProcessFee = this.FBM.data.calcOrderProcessFee?.cost
        data.total_price += Number(this.FBM.data.calcOrderProcessFee?.cost)
      }
      if(this.FBM.data.giftPackagingPrice?.price > 0) {
        data.orderGiftPackingPrice = this.FBM.data.giftPackagingPrice.price
        data.total_price += Number(this.FBM.data.giftPackagingPrice.price)
      }
      if(this.FBM.data.productsExtraFee > 0) {
        data.skladUSAFeePrice = this.FBM.data.productsExtraFee
        data.total_price += Number(this.FBM.data.productsExtraFee)
      }
      if(this.FBM.data.packagingType?.user_packing_price){
        data.orderPackingPrice = this.FBM.data.packagingType.user_packing_price.price
        data.total_price += Number(this.FBM.data.packagingType.user_packing_price.price)
      }
      if(this.FBM.data.isEngraving && this.FBM.data.Engravings.length > 0){
        data.engraving = 0
        this.FBM.data.Engravings.forEach(engravingItem => {
          if(Number(engravingItem.data.engravingPrice) >= 0) {
            data.engraving += Number(engravingItem.data.engravingPrice)
            data.total_price += Number(engravingItem.data.engravingPrice)
          }
        })
      }
      if(this.FBM.data.Embroidery.data.isEmbroidery && Number(this.FBM.data.Embroidery.data.resultCost) >= 0){
        if(!data.engraving) data.engraving = 0
        data.engraving += Number(this.FBM.data.Embroidery.data.resultCost)
        data.total_price += Number(this.FBM.data.Embroidery.data.resultCost)
      }
      if(this.FBM.data.TShirtPrinting.data.isTShirtPrinting && Number(this.FBM.data.TShirtPrinting.data.resultCost) >= 0){
        if(!data.engraving) data.engraving = 0
        data.engraving += Number(this.FBM.data.TShirtPrinting.data.resultCost)
        data.total_price += Number(this.FBM.data.TShirtPrinting.data.resultCost)
      }
      if(this.FBM.data.LeatherStamp.data.isLeatherStamp && Number(this.FBM.data.LeatherStamp.data.resultCost) >= 0){
        if(!data.engraving) data.engraving = 0
        data.engraving += Number(this.FBM.data.LeatherStamp.data.resultCost)
        data.total_price += Number(this.FBM.data.LeatherStamp.data.resultCost)
      }
      if(this.FBM.data.PhotoPrint.data.isPhotoPrint && Number(this.FBM.data.PhotoPrint.data.resultCost) >= 0){
        if(!data.engraving) data.engraving = 0
        data.engraving += Number(this.FBM.data.PhotoPrint.data.resultCost)
        data.total_price += Number(this.FBM.data.PhotoPrint.data.resultCost)
      }
      if(this.FBM.data.UVPrinting.data.isUVPrinting && Number(this.FBM.data.UVPrinting.data.resultCost) >= 0){
        if(!data.engraving) data.engraving = 0
        data.engraving += Number(this.FBM.data.UVPrinting.data.resultCost)
        data.total_price += Number(this.FBM.data.UVPrinting.data.resultCost)
      }

      /**
       * If user has easy orders
       */
      if(this.isUserHasEasyOrder && this.isConsolidation) {
        // data.price = Number(data.price) + Number(data.orderProcessFee)

        data.orderProcessFee = 0
      }


      return data
    },

    calculateOrderFee() {
      let isEditAdmin = this.FBM.data.Order.data.orderOBJ?.orderFbmInstance && this.isAdmin

      let width = isEditAdmin ? this.FBM.data.AdminDimensions.getDimensionsWidth() : this.FBM.data.Dimensions.getDimensionsWidth()
      let height = isEditAdmin ? this.FBM.data.AdminDimensions.getDimensionsHeight() : this.FBM.data.Dimensions.getDimensionsHeight()
      let length = isEditAdmin ? this.FBM.data.AdminDimensions.getDimensionsLength() : this.FBM.data.Dimensions.getDimensionsLength()

      if ( width !== '' && height !== '' && length !== '' ) {
        let perimeter = Number(width) + Number(height) + Number(length)
        let orderFeesList = this.FBM.data.Order.data.orderOBJ?.order_fees_list || this.FBM.data.orderFbmFees
        let defaultUserFee = this.FBM.data.Order.data.orderOBJ?.orderFbmInstance?.user?.user_setting?.order_process_fee || this.getUserOrderProcessFeeObject

        let foundOrderFee = this._.find(orderFeesList, (item) => {
          if(!item.imperial_perimeter_interval) {
            return false
          }

          if(isEditAdmin) {
            return Number(item.imperial_perimeter_interval.min) <= perimeter && perimeter <= Number(item.imperial_perimeter_interval.max)
          } else {
            return Number(item.metric_perimeter_interval.min) <= perimeter && perimeter <= Number(item.metric_perimeter_interval.max)
          }
        })

        if(defaultUserFee?.cost > foundOrderFee.cost) {
          return defaultUserFee
        }

        return foundOrderFee
      } else if(!isEditAdmin) {
        return this.getUserOrderProcessFeeObject
      }
    },

    calculatePrice() {
      return this.$options.filters
        .number(this.FBM.getTotalSumProformProduct(), '0.00')
    },

    getDeliveryMethodsKey(){
      if(this.isDestinationToUSA){
        return 'usa_delivery_methods';
      }

      return 'except_usa_delivery_methods'
    },

    isDestinationToUSA(){
      return this.FBM.getTrsnAddressCountry().id === USA_COUNTRY_ID
    },

    selectedCountry(){
      let delivery = this.FBM.data.transactionAddress !== this.FBM.data.transactionAddressTypes.etsy ?
          this.FBM.data.DeliveryPayPal : this.FBM.data.DeliveryEtsy
      return delivery.delivery.country
    },

    currentPersonalContactName() {
      let personal = this.FBM.data.transactionAddress !== this.FBM.data.transactionAddressTypes.etsy ?
          this.FBM.data.Personal : this.FBM.data.PersonalEtsy
      return personal?.information?.personalName
    },

    currentDeliveryZipCode() {
      let delivery = this.FBM.data.transactionAddress !== this.FBM.data.transactionAddressTypes.etsy ?
          this.FBM.data.DeliveryPayPal : this.FBM.data.DeliveryEtsy
      return delivery?.delivery?.zip
    },

    isSetWarehouse() {
      return this._.has(this.FBM.data.Order.getWarehouse(), 'id')
    },
  },

  methods: {

    downloadEngravingArray(engravingArray, type, name) {
      engravingArray.map(item => {
        this.downloadEngravingFile(item.data.EngravingFile, type, name)
      })
    },

    downloadEngravingFile(files, type = 'dxf', name = 'Customization file') {
      files.getDownloadFiles().map(objectFile => {
        let key = this._.first(Object.keys(objectFile))
        let file = objectFile[key]
        let nameExtension = file.type.indexOf('pdf') === -1 ? file.type.split('/')[1] : 'pdf'

        if(nameExtension === 'octet-stream')
          nameExtension = type

        if(nameExtension === 'postscript')
          nameExtension = 'ai'

        if(nameExtension === 'svg+xml')
          nameExtension = 'svg'

        this.$store.dispatch('downloadFileFromServer', file.id).then(response => {
          this.createDownloadLink({
            data: response.data,
            name: `${name} order - ${this.FBM.data.Order.getId()}.${nameExtension}`,
            type: file.type
          })
        })
      })

      this.FBM.data.engravingAdminDownloaded = true
    },

    createDownloadLink({data, name, type}){
      const blob = new Blob([data], {type: `${type}`})
      const link = document.createElement('a')
      link.href = URL.createObjectURL(blob)
      link.download = name
      link.click()
      URL.revokeObjectURL(link.href)
    },

    checkPermission(){
      return this.checkOrderTypePermission({
        types: {
          [FBM_ORDER_TYPES.FBM_ORDER_WAREHOUSE.name]: this.PERMISSIONS.ORDER_FBM_WAREHOUSE,
          [FBM_ORDER_TYPES.FBM_ORDER_CONSOLIDATION.name]: this.PERMISSIONS.ORDER_FBM_CONSOLIDATION,
          [FBM_ORDER_TYPES.FBM_ORDER_LABEL.name]: this.PERMISSIONS.ORDER_FBM_LABEL,
        },
        currentType: this.$route.params.type
      })
    },

    setProductPhoto(photo) {
      let newPhoto = []
      Object.keys(photo).map(photoKey => {
        newPhoto.push({
          id: photo[photoKey],
          bigImageId: photo[photoKey].big_image,
          base64: photo[photoKey].file,
          extension: photo[photoKey].extension,
        })
      })
      this.FBM.data.ProductPhoto.data.downloadFiles = newPhoto
    },

    /**
     * Take a photo by web camera
     */
    async takePhoto() {
      if(!this.isAdmin) return

      // Знаходимо елемент <video> в HTML-документі
      const video = document.querySelector('video');
      let mediaStream = null;

      // Запускаємо стрім з веб-камери
      await navigator.mediaDevices.getUserMedia({ video: {
          width: { min: 640, ideal: 1920, max: 1920 },
          height: { min: 480, ideal: 1080, max: 1080 }
        } })
        .then(stream => {
          mediaStream = stream
          video.srcObject = stream
          video.play()
        })
        .catch(err => {
          console.error("Не можу отримати доступ до відеокамери: ", err)
        });

      setTimeout(() => {
        // Знаходимо елемент <button> в HTML-документі
        // const button = document.querySelector('button');

        // Оброблюємо клік на кнопці
        // button.addEventListener('click', () => {
        // Створюємо елемент <canvas>
        const canvas = document.createElement('canvas')
        canvas.width = video.videoWidth
        canvas.height = video.videoHeight

        // Отримуємо контекст рисування для <canvas>
        const context = canvas.getContext('2d')
        context.drawImage(video, 0, 0, canvas.width, canvas.height)

        // Конвертуємо зображення з <canvas> в data URL
        const dataURL = canvas.toDataURL('image/png')

        // // Виводимо зображення на сторінку
        // const image = document.createElement('img');
        // image.src = dataURL;
        // document.body.appendChild(image);

        if(dataURL.length < 10) return

        let data = {
          "order_fbm_id": this.FBM.data.Order.getId(),
          "photo_base64": dataURL
        }

        this.$store.dispatch('sendProductPhotoFBM', data).then(response => {
          switch (this.getResponseStatus(response)) {
            /**
             * Success
             */
            case this.$store.getters.GET_ERRORS.SUCCESS_CODE: {
              console.log(23423);
              this.setProductPhoto(this.getRespData(response).photos)

              if (mediaStream) {
                // Отримати всі доріжки медіа в потоці
                const tracks = mediaStream.getTracks()

                // Зупинити всі доріжки медіа в потоці
                tracks.forEach(track => track.stop())

                // Звільнити ресурси
                mediaStream = null
              }

              // this.FBM.data.ProductPhoto.data.downloadFiles.push({
              //   id: 1,
              //   bigImageId: 2,
              //   base64: dataURL.split(';base64,')[1],
              //   extension: 'image/png',
              // })
              console.log(this.FBM.data.ProductPhoto.data.downloadFiles);
              break
            }
            /**
             * Validation Error
             */
            case this.$store.getters.GET_ERRORS.VALIDATION_ERROR_CODE: {
              let errors = response.response.data.errors;
              this.notifyErrorHelper(errors)
              break
            }
            /**
             * Undefined Error
             */
            default: {
              this.openNotify('error', 'common_notificationUndefinedError')
            }
          }
        })

        // var base64Image = dataURL.split(';')[1].replace('base64,', '');
        // console.log('Base64 зображення:', base64Image);

        // });
      }, 1000)
    },

    /**
     * Set Vat Files
     */
    setVatFiles(files) {
      let firstFile = this._.first(files)

      this.downloadFileFromServer(firstFile).then((dataFile) => {
        this.FBM.setVatNumberFile(dataFile)
      })
    },

    /**
     * Set Engraving Files
     */
    setEngravingFile(files, engravingIndex) {
      files.map(file => {
        this.downloadFileFromServer(file).then((dataFile) => {
          this.FBM.data.Engravings[engravingIndex].data.EngravingFile.data.downloadFiles.push(dataFile)
        })
      })
    },

    /**
     * Set Embroidery Files
     */
    setEmbroideryFile(files) {
      files.map(file => {
        this.downloadFileFromServer(file).then((dataFile) => {
          this.FBM.data.Embroidery.data.File.data.downloadFiles.push(dataFile)
        })
      })
    },

    /**
     * set T-Shirt Painting Files
     */
    setTShirtPaintingFile(files) {
      files.map(file => {
        this.downloadFileFromServer(file).then((dataFile) => {
          this.FBM.data.TShirtPrinting.data.File.data.downloadFiles.push(dataFile)
        })
      })
    },

    /**
     * set Photo Print Files
     */
    setPhotoPrintFilesFile(files) {
      files.map(file => {
        this.downloadFileFromServer(file).then((dataFile) => {
          this.FBM.data.PhotoPrint.data.File.data.downloadFiles.push(dataFile)
        })
      })
    },

    /**
     * set Leather Stamping Files
     */
    setLeatherStampingFile(files) {
      files.map(file => {
        this.downloadFileFromServer(file).then((dataFile) => {
          this.FBM.data.LeatherStamp.data.File.data.downloadFiles.push(dataFile)
        })
      })
    },

    /**
     * set Leather Stamping Files
     */
    setUVPrintingFile(files) {
      files.map(file => {
        this.downloadFileFromServer(file).then((dataFile) => {
          this.FBM.data.UVPrinting.data.File.data.downloadFiles.push(dataFile)
        })
      })
    },

    /**
     * Open order label by UUId
     */
    openLabelFBM(order) {
      let localUuid = order.file_entity[0]?.uuid

      if (order?.label_file?.uuid) {
        localUuid = order.label_file.uuid
      }

      let routeData = this.$router.resolve({
        path: this.$store.getters.GET_PATHS.getPdfFile + '/' + localUuid
      })

      window.open(routeData.href, '_blank');
    },

    /**
     * Open order label by UUId
     */
    getBarcodeFBM(order) {
      let localUuid = order.barcode_label_file?.uuid

      let routeData = this.$router.resolve({
        path: this.$store.getters.GET_PATHS.getPdfFile + '/' + localUuid
      })

      window.open(routeData.href, '_blank');
    },

    /**
     * Copy order by Id
     */
    copyOrder(id, fromTable = false) {
      this.$store.dispatch('copyOrdersFBM', {order_fbm_id: id}).then((response) => {
        if (!this.getResponseStatus(response)) return this.openNotify('error', 'common_notificationUndefinedError')

        if(fromTable){
          this.reload()
        } else {
          this.successSave(true)
        }
      })
    },

    /**
     * Delete Order by Id
     */
    deleteOrder(id, fromTable = false) {

      let text = {
        title: 'importO_deleteOrder',
        txt: 'importO_deleteOrderQuest',
        yes: 'common_confirmDelete',
        no: 'common_confirmNo'
      }

      let confirm = () => {

        this.$store.dispatch('deleteOrdersFBM', id).then((response) => {
          if (!this.getResponseStatus(response)) return this.openNotify('error', 'common_notificationUndefinedError')

          if(fromTable){
            this.reload()
          } else {
            this.successSave(true)
          }
        })

        this.$store.commit('setConfirmDeletePopup', false)
        return true
      }

      this.deleteFunc(text, confirm)
    },

    validateFranceZip() {
      if(this.FBM.getTrsnAddressCountry()?.id === 74 && this.FBM.getTrsnZip() + '' === '98000') {
        this.openNotify('error', 'common_notCountryWithZip')
        return false
      }
      return true
    },

    nextPageHandler() {
      let valid = true

      switch (this.activeStep) {
        case 1:
          valid = this.FBM.FBMFirstStepValidate(false, this) && this.validateFranceZip()
          break
        case 2:
          valid = this.FBM.FBMSecondStepValidate(false, this.checkSelectedUSACountry)
          break
        case 3:
          valid = this.FBM.FBMThirdStepValidate()
          if(!this.FBM.getShippingPartner()?.id){
            valid = false
            this.openNotify('error', 'fbm_ChooseShippingType')
          }
          if(!this.FBM.FBMPhoneValidate()){
            valid = false
            this.openNotify('error', 'fbm_PhoneNumberRequired')
            return false
          }
          if(this.isLabel && !this.FBM.data.isUserLabel && !this.FBM.getShippingAPIPrice()?.driver_delivery_method_id){
            valid = false
            this.openNotify('error', 'fbm_ChooseShippingPrice')
          }

          if((this.isUserPrepayment || (this.isEasyType && this.isConsolidation)) && !this.isLabel && valid) {
            this.loadingPrepayPrice = true
            this.prepayError = false
            this.FBM.data.price = 0
            this.FBM.setCalcOrderProcessFee(0)
            this.getShippingPrices(false, true).then(response => {
              console.log(777777777777777, this.getUserFBMAutodetectFee);
              if (this.getUserFBMAutodetectFee) {
                this.getCalculatedProcessFee(false, true).then(feeResponse => {
                  console.log(feeResponse);
                  //////////////////////////
                  this.loadingPrepayPrice = false
                  if(response?.errors){
                    this.prepayError = this._.first(response?.errors)
                  }
                  if(Object.keys(response).length === 0){
                    this.prepayError = this.$t('fbm_NoPricesAvailable.localization_value.value')
                    return
                  }
                  // console.log(this._.first(response)?.shipmentCost);
                  this.FBM.data.price = Number(this._.first(response)?.shipmentCost)
                  this.FBM.data.insurancePrice = Number(this._.first(response)?.insuranceCost)

                  let orderFeeFromResponse = feeResponse?.order_fee

                  console.log(orderFeeFromResponse, Number(orderFeeFromResponse?.cost));
                  if (orderFeeFromResponse) {
                    this.FBM.setCalcOrderProcessFee(orderFeeFromResponse)
                  }

                  //////////////////////////
                })
              }
              else {
                this.loadingPrepayPrice = false
                if(response?.errors){
                  this.prepayError = this._.first(response?.errors)
                }
                if(Object.keys(response).length === 0){
                  this.prepayError = this.$t('fbm_NoPricesAvailable.localization_value.value')
                  return
                }
                // console.log(this._.first(response)?.shipmentCost);
                this.FBM.data.price = Number(this._.first(response)?.shipmentCost)
                this.FBM.data.insurancePrice = Number(this._.first(response)?.insuranceCost)
              }

            })

            this.FBM.setProductsExtraFee(0)
            let countProductsForFee = 0
            this.FBM.data.productsForCellArray.forEach(prItem => {
              if (prItem.data?.Product?.product) {
                countProductsForFee += Number(prItem.data.Product.product.count)
              }
            })

            if (countProductsForFee > 3) {
              let prepData = {
                'id': this.FBM.data.Order.getId() ? this.FBM.data.Order.getId() : null,
                'product_count': countProductsForFee,
                'user_id': this.FBM.data.User.getUserId()
              }
              this.$store.dispatch('getOrdersFBMProductFee', prepData).then(feeResponse => {
                if (feeResponse?.data?.data?.product_process) {
                  this.FBM.setProductsExtraFee(feeResponse?.data?.data?.product_process)
                }
              }).catch(error => this.createErrorLog(error))
            }

          }
          break
      }

      if (valid)
        if (this.activeStep < this.progressStepsSetting.length) this.activeStep = this.activeStep + 1
    },

    expertUserValidate(calcPrepay = false, beforeSave = false) {
      if(!this.FBM.FBMFirstStepValidate(false, this) || !this.validateFranceZip() || !this.FBM.FBMSecondStepValidate(false, this.checkSelectedUSACountry)) {

        if(this.FBM.data.Personal.validation.personalName)
          return this.openNotify('error', 'fbm_ContactName')
        if(this.FBM.data.Personal.validation.emailOptional)
          return this.openNotify('error', 'fbm_Email')

        if(this.FBM.data.DeliveryPayPal.validation.address)
          return this.openNotify('error', 'fbm_Address')
        if(this.FBM.data.DeliveryPayPal.validation.city)
          return this.openNotify('error', 'fbm_City')
        if(this.FBM.data.DeliveryPayPal.validation.region)
          return this.openNotify('error', 'fbm_Region')
        if(this.FBM.data.DeliveryPayPal.validation.country)
          return this.openNotify('error', 'fbm_Country')
        if(this.FBM.data.DeliveryPayPal.validation.zip)
          return this.openNotify('error', 'fbm_ZipCode')

        return false
      }

      if (!this.FBM.FBMThirdStepValidate()) {
        return false
      }

      if(!this.FBM.getShippingPartner()?.id){
        this.openNotify('error', 'fbm_ChooseShippingType')
        return false
      }
      if(!this.FBM.FBMPhoneValidate()){
        this.openNotify('error', 'fbm_PhoneNumberRequired')
        return false
      }
      if(this.isLabel && !this.FBM.data.isUserLabel && !this.FBM.getShippingAPIPrice()?.driver_delivery_method_id){
        this.openNotify('error', 'fbm_ChooseShippingPrice')
        return false
      }

      if(this.isUserPrepayment && !this.isLabel && !beforeSave) {
        this.loadingPrepayPrice = true
        this.prepayError = false
        this.FBM.data.price = 0
        this.FBM.setCalcOrderProcessFee(0)
        this.getShippingPrices(false, true).then(response => {
          if (this.getUserFBMAutodetectFee) {
            this.getCalculatedProcessFee(false, true).then(feeResponse => {

              this.loadingPrepayPrice = false
              if(response?.errors){
                this.prepayError = this._.first(response?.errors)
              }
              if(Object.keys(response).length === 0){
                this.prepayError = this.$t('fbm_NoPricesAvailable.localization_value.value')
                return
              }

              this.FBM.data.price = Number(this._.first(response)?.shipmentCost)
              this.FBM.data.insurancePrice = Number(this._.first(response)?.insuranceCost)

              let orderFeeFromResponse = feeResponse?.order_fee

              console.log(orderFeeFromResponse, Number(orderFeeFromResponse?.cost));
              if (orderFeeFromResponse) {
                this.FBM.setCalcOrderProcessFee(orderFeeFromResponse)
              }

              if (calcPrepay && this.$refs?.fbmExpertUserPriceBlock?.$el) {
                setTimeout(() => {
                  this.$refs.fbmExpertUserPriceBlock.$el.scrollIntoView({behavior: 'smooth'})
                }, 500)
              }

            })
          }
          else {
            this.loadingPrepayPrice = false
            if(response?.errors){
              this.prepayError = this._.first(response?.errors)
            }
            if(Object.keys(response).length === 0){
              this.prepayError = this.$t('fbm_NoPricesAvailable.localization_value.value')
              return
            }
            this.FBM.data.price = Number(this._.first(response)?.shipmentCost)
            this.FBM.data.insurancePrice = Number(this._.first(response)?.insuranceCost)

            if (calcPrepay && this.$refs?.fbmExpertUserPriceBlock?.$el) {
              setTimeout(() => {
                this.$refs.fbmExpertUserPriceBlock.$el.scrollIntoView({behavior: 'smooth'})
              }, 500)
            }
          }

        })

        this.FBM.setProductsExtraFee(0)
        let countProductsForFee = 0
        this.FBM.data.productsForCellArray.forEach(prItem => {
          if (prItem.data?.Product?.product) {
            countProductsForFee += Number(prItem.data.Product.product.count)
          }
        })

        if (countProductsForFee > 3) {
          let prepData = {
            'id': this.FBM.data.Order.getId() ? this.FBM.data.Order.getId() : null,
            'product_count': countProductsForFee,
            'user_id': this.FBM.data.User.getUserId()
          }
          this.$store.dispatch('getOrdersFBMProductFee', prepData).then(feeResponse => {
            if (feeResponse?.data?.data?.product_process) {
              this.FBM.setProductsExtraFee(feeResponse?.data?.data?.product_process)
            }
          }).catch(error => this.createErrorLog(error))
        }

      }

      return true
    },

    /**
     * Change User
     * @param user
     * @param resetProduct
     */
    changeUser(user, resetProduct = true) {
      this.FBM.setUserData(user)
      if(resetProduct)
        this.FBM.resetProduct()
    },

    /**
     * initialize Shipping Companies
     */
    initShippingCompanies(adminManage, show = false) {
      let shippingCompanies = this.FBM.data.shippingCompanies

      /**
       * if dont choose partner set first partnerType and method
       */

      if(shippingCompanies.length > 0){
        let partner = this.FBM.data.shippingPartner

        let findMethod = false
        shippingCompanies.map(company => {
          if(partner && this._.find(company.shipping_methods, {id: partner.id})){
            findMethod = true
          }
        })

        if(findMethod){
          // this.isLabel && this.isConsolidation &&
          if(this.isEasyType && !adminManage && !show){
            console.log(3333);
            this.getShippingPrices()
          }
          return
        }

        this.FBM.setShippingPartnerType(shippingCompanies[0])
        this.FBM.setShippingPartner(this._.first(this._.first(shippingCompanies)['shipping_methods']))
        // this.isLabel ||
        //   ( adminManage) &&
        if(Object.keys(this.FBM.getShippingAPIPrice()).length === 0 || !this.FBM.getShippingAPIPrice()?.shipping_company){
          console.log(444);
          this.getShippingPrices()
        }

        // if(this._.first(shippingCompanies)['shipping_methods'].length === 1){
        //   this.FBM.setShippingPartner(this._.first(this._.first(shippingCompanies)['shipping_methods']))
        // } else {
        //   this.getShippingPrices()
        // }
      }

      // if(!this.FBM.data.shippingPartner || Object.keys(this.FBM.data.shippingPartner).length === 0){
      //   if(shippingCompanies.length === 0) return
      //
      //   if(!this._.find(shippingCompanies, {id: this.FBM.data.shippingPartnerType.id})) return
      //
      //   this.FBM.setShippingPartnerType(shippingCompanies[0])
      //
      //   if(this._.first(shippingCompanies)['shipping_methods'].length === 1){
      //     this.FBM.setShippingPartner(this._.first(this._.first(shippingCompanies)['shipping_methods']))
      //   }
      // }
    },

    checkShippingCompany(adminManage = false){
      /**
       * Reset ShippingCompanyData
       */
      // this.FBM.setShippingCompanies([])

      /**
       * If load user and not admin or order type Edit
       */
      if(this.FBM.data.User.getUserId() !== ''
        // && (!this.isAdmin || this.$route.params.id)
      ){
        return this.getShippingData(adminManage)
      }
    },

    getShippingData(adminManage = false){

      /**
       * if Easy order econom
       */
      if(!(Number(this.FBM.data.Dimensions.data.weightLB) > 0 || Number(this.FBM.data.Dimensions.data.weightOZ) > 0) && this.isEasyType && this.isConsolidation) {
        return
      }

      // this.$store.dispatch('getOrdersFromOrderType', this.FBM.data.Order.getType().id)

      let prepareFBM = this.FBM.prepareFBM(false,false,false,false,true)

      let prepareData = {
        "recipient_country_id": prepareFBM.recipient_country_id,
        "recipient_zip_code": prepareFBM.recipient_zip_code,
      }

      if(prepareFBM['dimension']) {
        prepareData['dimension'] = prepareFBM['dimension']
      }

      if(prepareFBM['admin_dimension']) {
        prepareData['admin_dimension'] = prepareFBM['admin_dimension']
      }

      prepareData['order_type'] = this.FBM.data.Order.getType()?.value

      prepareData['require_signature'] = prepareFBM.require_signature

      if(!this.isLabel) {
        prepareData['vat_tax'] = 'example'
      }

      if(prepareFBM.storage_id) {
        prepareData['storage_id'] = prepareFBM.storage_id
      }

      if(prepareFBM?.vat_tax?.vat_tax) {
        prepareData['vat_tax'] = prepareFBM['vat_tax']['vat_tax']
      }

      if(adminManage){
        prepareData['delivery_date'] = this.FBM.data.receivedAt
      }

      if(this.FBM.getIdReturned()) {
        prepareData['return_parcel_id'] = parseInt(this.FBM.getIdReturned())
      }

      prepareData['user_id'] = this.FBM.data.User.getUserId()

      let dispatch = 'getOrderShippingCompany'

      if(this.isEasyType) {
        if(this.isConsolidation) dispatch = 'getEasyEconomShippingCompany'

        // if(this.isWarehouse) dispatch = 'getEasyWarehouseShippingCompany'
      }

      return this.$store.dispatch(dispatch, prepareData).then(response => {
        let data = this.getRespData(response)

        /**
         * Replace ids if Econom type
         */
        // data.forEach(company => {
        //   if(company?.delivery_company_id === 'econom') {
        //     company.delivery_company_id = -1
        //     company.shipping_methods.forEach(method => {
        //       method.id = -1
        //     })
        //   }
        //   if(company?.delivery_company_id === 'econom_europe') {
        //     company.delivery_company_id = -2
        //     company.shipping_methods.forEach(method => {
        //       method.id = -2
        //     })
        //   }
        // })
        //
        // console.log(221321312);
        // console.log(data);

        this.FBM.setShippingCompanies(data)
        // fix copy bug when list is empty and we already have shipping type
        if (data.length === 0) {
          this.FBM.setShippingPartnerType({})
          this.FBM.setShippingPartner({})

          this.openNotify('error', 'common_notFoundMatchingCompany')

        } else if(this.FBM.getShippingPartnerType()?.delivery_company_id && this.FBM.getShippingPartner()?.id) {
          let partnerType = this._.find(data, {delivery_company_id: this.FBM.getShippingPartnerType()?.delivery_company_id})
          if(!partnerType?.shipping_methods) return

          let partner = this._.find(partnerType?.shipping_methods, {id: this.FBM.getShippingPartner()?.id})
          if(!partner) return

          this.FBM.data.shippingPartner.can_requaire_signature = partner.can_requaire_signature
          this.FBM.data.shippingPartner.need_paid_vat = partner.need_paid_vat
        }
        // setShippingAPIPrices
      }).catch(error => this.createErrorLog(error))
    },

    getShippingPrices(adminManage = false, getPrepayCost = false) {

      let prepareFBM = this.FBM.prepareFBM(false, false, false, adminManage, true, false, false, true)

      let prepareData = {
        "user_id": prepareFBM.user_id,
        "order_type_id" : prepareFBM.order_type_id,
        "recipient_contact_name": prepareFBM.recipient_contact_name,
        "recipient_phone_number" : prepareFBM.recipient_phone_number,
        "recipient_company_name": prepareFBM.recipient_company_name,
        "recipient_address": prepareFBM.recipient_address,
        "recipient_address_1": prepareFBM.recipient_address_1,
        "recipient_city": prepareFBM.recipient_city,
        "recipient_region": prepareFBM.recipient_region,
        "recipient_country_id": prepareFBM.recipient_country_id,
        "recipient_zip_code": prepareFBM.recipient_zip_code,
        "insurance_amount" : prepareFBM.insurance_amount,
        "delivery_company_id" : prepareFBM.delivery_company_id,
        "delivery_method_id" : prepareFBM.delivery_method_id,
        "saturday_delivery" : prepareFBM.saturday_delivery,
        "require_signature" : prepareFBM.require_signature,
      }

      if(prepareFBM.payment_transaction_id){
        prepareData.payment_transaction_id = prepareFBM.payment_transaction_id
      }

      if(prepareFBM.storage_id) {
        prepareData['storage_id'] = prepareFBM.storage_id
      }

      if(prepareFBM?.vat_tax?.vat_tax && prepareFBM?.vat_tax?.vat_tax.length > 0 && !this.isLabel){
        prepareData.vat_tax = prepareFBM?.vat_tax?.vat_tax
      }

      if(this.FBM.getIdReturned()) {
        prepareData['return_parcel_id'] = parseInt(this.FBM.getIdReturned())
      }

      if(this.FBM.getShippingAPIPrice()?.driver_delivery_method_id && getPrepayCost) {
        prepareData['driver_delivery_method_id'] = this.FBM.getShippingAPIPrice()?.driver_delivery_method_id
      }

      // prepareData['delivery_date'] = "2022-05-14"
        // "dimension": prepareFBM.dimension

      // add customsClearance if dpd and not visibleVatNumber

      if(!this.FBM.data.visibleVatNumber && prepareFBM.addition_info?.info && Number(prepareFBM.addition_info.info.tax_clearance.cost) !== 0){
        prepareData["additional_cost"] = prepareFBM.addition_info.info.tax_clearance.cost
        prepareData.vat_tax = 'example'
      }

      if(adminManage){
        prepareData["admin_dimension"] = prepareFBM.admin_dimension

        if(prepareFBM.delivery_date && prepareFBM.delivery_date !== ''){
          prepareData["delivery_date"] = prepareFBM.delivery_date
        }
      } else {
        prepareData["dimension"] = prepareFBM.dimension

        if(prepareFBM["saturday_delivery"] === 1 && this.getUserPlannedDate){
          prepareData["delivery_date"] = this.$moment(this.getUserPlannedDate).format('YYYY-MM-DD')
        }
      }

      /**
       * if user dimensions is empty -> return
       */
      if(!adminManage && this._.first(prepareData["dimension"])?.weightKg === '' && this._.first(prepareData["dimension"])?.weightGr === '')
        return

      /**
       * if not have delivery company -> return
       */
      if(!prepareData["delivery_company_id"] || !prepareData["delivery_method_id"])
        return

      let dispatch = 'getOrderShippingPrices'

      if(this.isEasyType && this.isConsolidation) {
        prepareData['easy_order_shipping_company_code'] = this.FBM.data.shippingPartnerType?.code

        // if(this.isConsolidation)
        dispatch = 'getEasyEconomRate'

        // if(this.isWarehouse) dispatch = 'getEasyWarehouseRate'
      }

      return this.$store.dispatch(dispatch, prepareData).then(response => {
        if (!this.getResponseStatus(response)) return this.openNotify('error', 'common_notificationUndefinedError')

        switch (this.getResponseStatus(response)) {
          /**
           * Success
           */
          case this.$store.getters.GET_ERRORS.SUCCESS_CODE: {
            let data = this.getRespData(response)

            if(getPrepayCost){
              return data
            }

            this.FBM.setShippingAPIPrices(data)

            if(data.length === 1){
              this.FBM.setShippingAPIPrice(this._.first(data))
            } else {
              this.FBM.setShippingAPIPrice({})
            }
            break
          }
          /**
           * Validation Error
           */
          case this.$store.getters.GET_ERRORS.VALIDATION_ERROR_CODE: {
            let errors = response.response.data.errors;

            this.FBM.setShippingAPIPrices([])
            this.FBM.setShippingAPIPrice({})

            this.notifyErrorHelper(errors)
            return response.response.data
          }
          /**
           * Undefined Error
           */
          default: {
            this.openNotify('error', 'common_notificationUndefinedError')
          }
        }

      }).catch(error => this.createErrorLog(error))
    },

    getCalculatedProcessFee(adminManage = false, getPrepayCost = false) {
      console.log(555555555555555555);
      let prepareFBM = this.FBM.prepareFBM(false, false, false, adminManage, true)
      console.log(555555555555555555);
      let prepareData = {
        "user_id": prepareFBM.user_id,
        "order_type_id" : prepareFBM.order_type_id,
        "recipient_contact_name": prepareFBM.recipient_contact_name,
        "recipient_phone_number" : prepareFBM.recipient_phone_number,
        "recipient_company_name": prepareFBM.recipient_company_name,
        "recipient_address": prepareFBM.recipient_address,
        "recipient_address_1": prepareFBM.recipient_address_1,
        "recipient_city": prepareFBM.recipient_city,
        "recipient_region": prepareFBM.recipient_region,
        "recipient_country_id": prepareFBM.recipient_country_id,
        "recipient_zip_code": prepareFBM.recipient_zip_code,
        "insurance_amount" : prepareFBM.insurance_amount,
        "delivery_company_id" : prepareFBM.delivery_company_id,
        "delivery_method_id" : prepareFBM.delivery_method_id,
        "saturday_delivery" : prepareFBM.saturday_delivery,
      }

      if(prepareFBM.payment_transaction_id){
        prepareData.payment_transaction_id = prepareFBM.payment_transaction_id
      }

      if(prepareFBM?.vat_tax?.vat_tax && prepareFBM?.vat_tax?.vat_tax.length > 0 && !this.isLabel){
        prepareData.vat_tax = prepareFBM?.vat_tax?.vat_tax
      }

      // prepareData['delivery_date'] = "2022-05-14"
      // "dimension": prepareFBM.dimension

      // add customsClearance if dpd and not visibleVatNumber

      // if(!this.FBM.data.visibleVatNumber && this.FBM.data.customsClearance !== 0){
      //
      //   prepareData["additional_cost"] = this.FBM.data.customsClearance
      // }

      if(adminManage){
        prepareData["admin_dimension"] = prepareFBM.admin_dimension

        if(prepareFBM.delivery_date && prepareFBM.delivery_date !== ''){
          prepareData["delivery_date"] = prepareFBM.delivery_date
        }
      } else {
        prepareData["dimension"] = prepareFBM.dimension

        if(prepareFBM["saturday_delivery"] === 1 && this.getUserPlannedDate){
          prepareData["delivery_date"] = this.$moment(this.getUserPlannedDate).format('YYYY-MM-DD')
        }
      }

      return this.$store.dispatch('getOrderCalculatedProcessFee', prepareData).then(response => {
        console.log(555555555555555555);
        if (!this.getResponseStatus(response)) return this.openNotify('error', 'common_notificationUndefinedError')

        switch (this.getResponseStatus(response)) {
            /**
             * Success
             */
          case this.$store.getters.GET_ERRORS.SUCCESS_CODE: {
            let data = this.getRespData(response)

            if(getPrepayCost){
              return data
            }

            // this.FBM.setShippingAPIPrices(data)

            // if(data.length === 1){
            //   this.FBM.setShippingAPIPrice(this._.first(data))
            // }
            break
          }
            /**
             * Validation Error
             */
          case this.$store.getters.GET_ERRORS.VALIDATION_ERROR_CODE: {
            let errors = response.response.data.errors;

            // this.FBM.setShippingAPIPrices([])
            // this.FBM.setShippingAPIPrice({})

            this.notifyErrorHelper(errors)
            return response.response.data
          }
            /**
             * Undefined Error
             */
          default: {
            this.openNotify('error', 'common_notificationUndefinedError')
          }
        }

      })
    },

    async prepareSaveFBM({draft = false, edit = false, createLabel = false, adminManage = false}) {

      await this.$store.dispatch('updateUserBalance')

      /**
       * If user create ROYAL_MAIL_COMPANY and not vat paid first click = warn
       */
      if(!this.isAdmin && !this.FBM.data.visibleVatNumber && this.checkIsMarketplaceCompany && Number(this.FBM.data.customsClearance) > 0 && this.isFirstCreateCusClearance) {
        this.openNotify('warn', {
          txtServer: `${this.$t('common_CusClearanceWarn.localization_value.value')} : $${this.FBM.data.customsClearance} - ${this.$t('common_CusClearanceWarn2.localization_value.value')}`
        })
        this.isFirstCreateCusClearance = false
        return
      }

      let reCreateLabel = false

      if(adminManage &&
        !this.isStatusComplete(this.FBM.data.Order.getOldStatus().value) &&
        !this.isStatusComplete(this.FBM.data.Order.getStatus().value)) {
        this.takePhoto()
      }

      if(this.isAdmin) {
        // check field product price & product description
        if(this._.has(this.FBM.FBMAdminValidation(edit, this.checkSelectedUSACountry), 'productDataForCountry')){
          this.openNotify('error', 'fbm_ChooseProductData')
          return
        }
        if(!this.FBM.FBMAdminValidation(edit, this.checkSelectedUSACountry)) return


        if(!this.FBM.data.Order.getIsWasBeenCreateLabel()) {
          if(!this.FBM.FBMCellCountsValidate(edit) && this.isStatusComplete(this.FBM.data.Order.getStatus().value)) {
            this.openNotify('error', 'common_ChooseProductCell')
            return
          }

          if(!this.FBM.FBMCellCountsValidate(edit) && Object.hasOwnProperty.call(this.FBM.data.shippingAPIPrice, 'name')) {
            this.openNotify('error', 'common_ChooseProductCell')
            return
          }

          // if(!this.FBM.FBMScannedInKievValidate()) {
          //   this.openNotify('error', 'common_ChooseScannedInKiev')
          //   return
          // }

          if(
            edit &&
            !this.isStatusComplete(this.FBM.data.Order.getOldStatus().value) &&
            !this.isStatusComplete(this.FBM.data.Order.getStatus().value) &&
            this.FBM.data.productsForCellArray.length > 0 &&
            this.FBM.FBMCellCountsValidate(edit) &&
            !Object.hasOwnProperty.call(this.FBM.data.shippingAPIPrice, 'name')
          ) {
            this.openNotify('error', 'common_CalculatePrice')
            return
          }
        }
      }


      if(adminManage && this.isStatusComplete(this.FBM.data.Order.getStatus().value)){
        let shippingAPIPrice = this.FBM.getShippingPartner()?.id
        if(!shippingAPIPrice){
          this.openNotify('error', 'fbm_ChooseShippingType')
          return
        }
      }

      /**
       * if Admin Manage and Status In progress and order dont wasBeenCreateLabel - create label = true
       */

      if(adminManage && this.isStatusInProgress(this.FBM.data.Order.getStatus().value)) {
        if(!this.FBM.data.Order.getIsWasBeenCreateLabel() && this.FBM.data.useEasyPost) {
          createLabel = true
        }
      }

      //only check if admin changed status to complete or will be creating label
      if(adminManage && (createLabel || this.isStatusComplete(this.FBM.data.Order.getStatus().value))){
        let packingPriceValid = this.FBM.FBMAdminValidationPackingPrice()
        if(!packingPriceValid){
          this.openNotify('error', 'fbm_ChoosePackagePrice')
          return
        }
      }

      if(adminManage &&
        this.isStatusComplete(this.FBM.data.Order.getStatus().value) &&
        this.FBM.data.Order.getIsWasBeenCreateLabel() &&
        this.FBM.data.useEasyPost &&
        createLabel) {
        reCreateLabel = true
      }

      let plannedDateWarehouse = false
      let plannedDateConsolidation = false
      if (adminManage && this.getUserPlannedDateWarehouse && this.isWarehouse) {
        plannedDateWarehouse = this.getUserPlannedDateWarehouse
      }
      if (adminManage && this.getUserPlannedDateConsolidation && this.isConsolidation) {
        plannedDateWarehouse = this.getUserPlannedDateConsolidation
      }


      /**
       * Engraving Files Admin validate
       */
      if(adminManage && (createLabel || (this.FBM.data.Order.data.orderOBJ?.orderFbmInstance?.own_label === true && this.isStatusComplete(this.FBM.data.Order.getStatus().value))) &&
        (this.FBM.data.isEngraving || this.FBM.data.Embroidery.data.isEmbroidery || this.FBM.data.TShirtPrinting.data.isTShirtPrinting ||
          this.FBM.data.LeatherStamp.data.isLeatherStamp || this.FBM.data.PhotoPrint.data.isPhotoPrint || this.FBM.data.UVPrinting.data.isUVPrinting
        ) &&
        !this.FBM.data.engravingAdminDownloaded && !this.FBM.data.engravingAdminCheck) {
        this.openNotify('error', 'fbm_DownloadEngravingFile')
        return
      }

      if(adminManage && this.FBM.data.engravingAdminDownloaded && !this.FBM.validateEngravingTime()) {
        return
      }
      /** **/

      let data = this.FBM.prepareFBM(
        draft,
        this.FBM.data.User.getUserId(),
        this.isAdmin,
        adminManage,
        true,
        edit,
        this.getUserFBMAutodetectFee,
        false,
        plannedDateWarehouse,
        plannedDateConsolidation,
      )

      if(draft){
        let confirm = async () => {


          /**
           * Validating User Label File
           */
          let validatingUserLabelFile = true
          if(this.FBM.data.isUserLabel && this.FBM.data.UserLabel.getFiles().length > 0) {
            validatingUserLabelFile = await this.validatingUserLabelFile(edit ? this.FBM.data.Order.getId() : null)
          }
          if(!validatingUserLabelFile) return


          /**
           * Validating Customization File
           * return Boolean
           */
          let validCustomization = await this.validatingCustomizationFiles()
          if(!validCustomization) return

          this.saveOrderFBM(draft, edit, data).then(async response => {

            /**
             * Uploading User Label File
             */
            let uploadedUserLabelFile = false
            if(this.FBM.data.isUserLabel && this.FBM.data.UserLabel.getFiles().length > 0 && response?.id) {
              uploadedUserLabelFile = await this.uploadUserLabelFile(response.id)
            }
            console.log(uploadedUserLabelFile);

            /**
             * Uploading Customization File
             * return Boolean
             */

            if(response?.id) {
              await this.uploadCustomizationFiles(response?.id)
            }

            this.successSave(response)
          })

          this.$store.commit('setSaveInDraftsPopup', false)
          return true
        }

        this.saveInDraftsFunc(confirm)
        return
      }

      if(!draft){

        //I will create label, если у юзера не хватает средств на балансе, то не создаем лэйбу
        if (this.isLabel && createLabel && !this.isAdmin && (+this.getCurrentUserProfile?.balance < (+this.FBM.getSkladUsaFee() + +this.FBM.getShippingAPIPrice()?.insuranceCost +
            +this.FBM.getShippingAPIPrice()?.shipmentCost + +this.FBM.getInsuranceCost()
          // + (this.FBM.data.shippingPartnerType?.signature_price ? +this.FBM.data.shippingPartnerType?.signature_price : 0)
          ))

          && !this.getUserICreateLabelUseVipparcel
        ) {
          this.openNotify('error', 'fbm_noFundsForLabel')
          return
        }

        // console.log(1111111);
        // console.log(this.isEasyType);
        // console.log(+this.FBM.getSkladUsaFee());
        // console.log(+this.FBM.getShippingAPIPrice()?.insuranceCost || 0);
        // console.log(+this.FBM.getShippingAPIPrice()?.shipmentCost);
        // console.log(+this.FBM.getInsuranceCost());
        // console.log(+this.getCurrentUserProfile?.balance);

        //Якщо ізі ордер і у юзера не має грошей то не створюємо
        if(this.isEasyType && !this.isAdmin) {
          // якщо редагуємо і ордер не був збереженим в драфті і включений препей
          if(edit && !this.FBM.data.Order.data.orderOBJ?.orderFbmInstance?.in_draft) { //&& this.FBM.data.Order.data.orderOBJ?.orderFbmInstance?.prepay === 1
            // перевіряємо суму яку вже було знято у юзера при створенні ордера, і додаємо до поточного балансу
            if ((+this.getCurrentUserProfile?.balance + +this.FBM.data.Order.data.orderOBJ?.orderFbmInstance?.prepay_delivery_cost) <
              (+this.FBM.getSkladUsaFee() + (+this.FBM.getShippingAPIPrice()?.insuranceCost || 0) +
              +this.FBM.getShippingAPIPrice()?.shipmentCost + +this.FBM.getInsuranceCost())
            ) {
              this.openNotify('error', 'fbm_noFundsForLabel')
              return
            }

          } else {
            // якщо створення
            if (+this.getCurrentUserProfile?.balance < (+this.FBM.getSkladUsaFee() + (+this.FBM.getShippingAPIPrice()?.insuranceCost || 0) +
              +this.FBM.getShippingAPIPrice()?.shipmentCost + +this.FBM.getInsuranceCost())
            ) {
              this.openNotify('error', 'fbm_noFundsForLabel')
              return
            }
          }
        }




        /**
         * Validating User Label File
         */
        let validatingUserLabelFile = true
        if(this.FBM.data.isUserLabel && this.FBM.data.UserLabel.getFiles().length > 0) {
          validatingUserLabelFile = await this.validatingUserLabelFile(edit ? this.FBM.data.Order.getId() : null)
        }
        if(!validatingUserLabelFile) return

        /**
         * Validating Customization File
         * return Boolean
         */
        let validCustomization = await this.validatingCustomizationFiles()
        console.log(222);
        console.log(validCustomization);
        if(!validCustomization) return

        this.saveOrderFBM(draft, edit, data, createLabel).then(async response => {

          /**
           * Uploading User Label File
           */
          let uploadedUserLabelFile = false
          if(this.FBM.data.isUserLabel && this.FBM.data.UserLabel.getFiles().length > 0 && response?.id) {
            uploadedUserLabelFile = await this.uploadUserLabelFile(response.id)
          }
          console.log(uploadedUserLabelFile);

          /**
           * Uploading Customization File
           * return Boolean
           */

          if(response?.id) {
            await this.uploadCustomizationFiles(response?.id)
          }


          /**
           * ReCreateLabel if true
           */
          if(reCreateLabel) {
            this.reCreateLabel(response.id, adminManage).then(status => {
              if(status) {
                this.successSave(status, createLabel, response.id, false, edit)
              }
            })
            return
          }

          /**
           * CreateLabel if true
           */
          if(createLabel) {
            this.createLabel(response.id, adminManage).then(status => {
              if(status) {
                if (this.FBM.getGiftMassage() && this.FBM.getGiftPaper()) {
                  this.generateGiftMsgFile(this.FBM.data.Order.getId(), this.FBM.getGiftMassage()).then(status => {
                        if (status) {
                          this.successSave(status, createLabel, response.id, false, edit, true)
                        }
                        else {
                          this.successSave(status, createLabel, response.id, false, edit, false)
                        }
                      }
                  )
                }
                else {
                  this.successSave(status, createLabel, response.id, false, edit)
                }
              }
              else {
                if(!adminManage && createLabel) {
                  this.$router.push(this.$store.getters.GET_PATHS.ordersFBM + '/' + 'label' + '/' + response.id)
                }
              }
            })
            return
          }

          /**
           * Open gift message if own_label
           */
          console.log(777);
          console.log(this.FBM.data.Order.data.orderOBJ?.orderFbmInstance);
          console.log(this.isStatusComplete(this.FBM.data.Order.getStatus().value));
          console.log(this.isStatusComplete(this.FBM.data.Order.getOldStatus().value));
          console.log(this.FBM.getGiftMassage());
          console.log(this.FBM.getGiftPaper());
          if(this.FBM.data.Order.data.orderOBJ?.orderFbmInstance?.own_label === true &&
            this.isStatusComplete(this.FBM.data.Order.getStatus().value) &&
            !this.isStatusComplete(this.FBM.data.Order.getOldStatus().value) &&
            this.FBM.getGiftMassage() && this.FBM.getGiftPaper()) {

            this.generateGiftMsgFile(this.FBM.data.Order.getId(), this.FBM.getGiftMassage()).then(status => {
              if (status) {
                this.successSave(status, createLabel, response.id, false, edit, true)
              } else {
                this.successSave(status, createLabel, response.id, false, edit, false)
              }
            })
            return
          }

          /**
           * Default save
           */
          this.successSave(response.status, false, response.id, response.prepay === 0, edit)
        })

        // this.saveOrderInternal(draft, edit, data, 'ukrposhta').then(response => {
        //   this.createLabel(response.id)
        //   return true
        // })
      }

    },

    async uploadCustomizationFiles(orderId) {
      let reader = new FormData();
      let prepareData = this.prepareCustomizationFilesData(reader, orderId)

      if(!prepareData.hasFiles) return new Promise(resolve => resolve(true))

      let typeDispatch = 'uploadCustomizationFilesFBM'

      return this.$store.dispatch(typeDispatch, reader).then((response) => {
        return this.processingCustomizationFilesResponse(response)
      })
    },

    async validatingCustomizationFiles() {
      let reader = new FormData();
      let prepareData = this.prepareCustomizationFilesData(reader)

      if(!prepareData.hasFiles) return new Promise(resolve => resolve(true))

      reader = prepareData.reader

      // for (var pair of reader.entries()) {
      //   console.log(pair[0]+ ', ' + pair[1]);
      // }

      return this.$store.dispatch('validatingCustomizationFilesFBM', reader).then((response) => {
        return this.processingCustomizationFilesResponse(response)
      })
    },

    prepareCustomizationFilesData(reader, orderId = false) {
      let hasFiles = false
      if(orderId) {
        reader.append("order_fbm_id", orderId)
      }

      if(this.FBM.data.isEngraving && this.FBM.data.Engravings.length > 0) {
        let firstFlag = false
        this.FBM.data.Engravings.forEach((engravingItem, engravingIndex) => {
          if(engravingItem.data.EngravingFile.getFiles().length > 0) {
            if(!firstFlag) {
              reader.append('engravings', [])
            }
            if(engravingItem.data.id) {
              reader.append(`engravings[${engravingIndex}][id]`, engravingItem.data.id)
            }
            reader.append(`engravings[${engravingIndex}][system_code]`, engravingIndex)
            engravingItem.data.EngravingFile.getFiles().forEach((item, index) => {
              reader.append(`engravings[${engravingIndex}][file${ index + 1 }]`, item.file)
            })

            firstFlag = true
            hasFiles = true
          }
        })
      }

      if(this.FBM.data.Embroidery.data.isEmbroidery && this.FBM.data.Embroidery.data.File.getFiles().length > 0) {
        reader.append('embroidery', [])
        this.FBM.data.Embroidery.data.File.getFiles().forEach((item, index) => {
          reader.append(`embroidery[file${ index + 1 }]`, item.file)
        })
        hasFiles = true
      }

      if(this.FBM.data.TShirtPrinting.data.isTShirtPrinting && this.FBM.data.TShirtPrinting.data.File.getFiles().length > 0) {
        reader.append('ts_painting', [])
        this.FBM.data.TShirtPrinting.data.File.getFiles().forEach((item, index) => {
          reader.append(`ts_painting[file${ index + 1 }]`, item.file)
        })
        hasFiles = true
      }

      if(this.FBM.data.PhotoPrint.data.isPhotoPrint && this.FBM.data.PhotoPrint.data.File.getFiles().length > 0) {
        reader.append('photo_print', [])
        this.FBM.data.PhotoPrint.data.File.getFiles().forEach((item, index) => {
          reader.append(`photo_print[file${ index + 1 }]`, item.file)
        })
        hasFiles = true
      }

      if(this.FBM.data.LeatherStamp.data.isLeatherStamp && this.FBM.data.LeatherStamp.data.File.getFiles().length > 0) {
        reader.append('leather_stamp', [])
        this.FBM.data.LeatherStamp.data.File.getFiles().forEach((item, index) => {
          reader.append(`leather_stamp[file${ index + 1 }]`, item.file)
        })
        hasFiles = true
      }

      if(this.FBM.data.UVPrinting.data.isUVPrinting && this.FBM.data.UVPrinting.data.File.getFiles().length > 0) {
        reader.append('uv_print', [])
        this.FBM.data.UVPrinting.data.File.getFiles().forEach((item, index) => {
          reader.append(`uv_print[file${ index + 1 }]`, item.file)
        })
        hasFiles = true
      }

      return {reader: reader, hasFiles: hasFiles}
    },

    uploadUserLabelFile(orderId) {
      let reader = new FormData();
      reader.append("order_fbm_id", orderId)

      this.FBM.data.UserLabel.getFiles().forEach((item) => {
        reader.append(`file`, item.file);
      })

      let typeDispatch = 'uploadUserLabelFileFBM'

      return this.$store.dispatch(typeDispatch, reader).then((response) => {
        return this.processingCustomizationFilesResponse(response)
      })
    },

    validatingUserLabelFile(orderId = null) {
      let reader = new FormData();

      this.FBM.data.UserLabel.getFiles().forEach((item) => {
        reader.append(`file`, item.file)
        if(orderId) {
          reader.append(`order_fbm_id`, orderId)
        }
      })

      return this.$store.dispatch('validatingUserLabelFileFBM', reader).then((response) => {
        return this.processingCustomizationFilesResponse(response)
      })
    },

    processingCustomizationFilesResponse(response) {
      switch (this.getResponseStatus(response)) {
        /**
         * Success
         */
        case this.$store.getters.GET_ERRORS.SUCCESS_CODE: {
          return true
        }
        /**
         * Validation Error
         */
        case this.$store.getters.GET_ERRORS.VALIDATION_ERROR_CODE: {
          let errors = response.response.data.errors;
          let firstItemError = errors[this._.first(Object.keys(errors))]

          if(Array.isArray(firstItemError)){

            let nameField = this._.first(this._.first(Object.keys(errors)).split('.'))

            if(nameField === 'ts_painting') {
              nameField = 't-shirt printing'
            }

            if(nameField === 'leather_stamp') {
              nameField = 'leather stamp'
            }

            if(nameField === 'photo_print') {
              nameField = 'photo print'
            }

            this.openNotify('error', {txtServer: nameField + ': ' + this._.first(firstItemError)})
            return false
          }
          this.notifyErrorHelper(errors)
          return false
        }
        /**
         * Undefined Error
         */
        default: {
          this.openNotify('error', 'common_notificationUndefinedError')
          return false
        }
      }
    },

    saveOrderFBM(draft = false, edit, data, createLabel) {
      console.log(draft);

      let saveType = 'createOrdersFBM'

      if(this.isEasyType) {
        saveType = this.isWarehouse ? 'createEasyOrdersFBM' : 'createEasyOrdersFBMConsolidation'
      }

      if(edit){
        data = {
          id: this.FBM.data.Order.getId(),
          data: data
        }
        console.log(data);
        console.log('check data');
        saveType = this.isAdmin ? 'updateOrdersFBMByAdmin' : 'updateOrdersFBM'

        if(this.isEasyType && !this.isAdmin) {
          saveType = this.isWarehouse ? 'updateEasyOrdersFBM' : 'updateEasyOrdersFBMConsolidation'
        }
      }

      return this.$store.dispatch(saveType, data).then(response => {

        switch (this.getResponseStatus(response)) {
          /**
           * Success
           */
          case this.$store.getters.GET_ERRORS.SUCCESS_CODE: {
            if(createLabel){
              return {status: true, id: response.data.data.id}
            }

            if(edit) {
              this.openNotify('success', 'common_notificationRecordChanged')
              return {status: true, id: response.data.data.id}
            }

            this.openNotify('success', 'common_notificationRecordCreated')
            return {status: true, id: response.data.data.id}
          }
          /**
           * Validation Error
           */
          case this.$store.getters.GET_ERRORS.VALIDATION_ERROR_CODE: {
            let errors = response.response.data.errors;
            this.notifyErrorHelper(errors)
            break
          }
          /**
           * Undefined Error
           */
          default: {
            this.openNotify('error', 'common_notificationUndefinedError')
          }
        }

        // if (this._.has(response, 'data') &&
        //   response.data.status === this.$store.getters.GET_ERRORS.SUCCESS_CODE) {
        //
        //   if(createLabel){
        //     return {status: true, id: response.data.data.id}
        //   }
        //
        //   if(edit) {
        //     this.openNotify('success', 'common_notificationRecordChanged')
        //     return {status: true, id: response.data.data.id}
        //   }
        //
        //   this.openNotify('success', 'common_notificationRecordCreated')
        //   return {status: true, id: response.data.data.id}
        //
        // } else if (response.response.data.status === this.$store.getters.GET_ERRORS.VALIDATION_ERROR_CODE) {
        //   alert('novalid')
        // } else {
        //   this.openNotify('error', 'common_notificationUndefinedError')
        // }
      })
    },

    successSave(status, openLabel = false, id = false, noPrepay = false, edit = false, openGiftFile = false) {
      if(!status) return

      if(id && this.isAdmin && edit) {
        if(openLabel){
          this.openNotify('success', 'common_notificationRecordChanged')
        }
        console.log(2323232);
        console.log('5');
        console.log(openGiftFile);
        this.reloadEditPage(openLabel, openGiftFile)
        return
      }

      if(openLabel && id){
        this.$router.push(this.$store.getters.GET_PATHS.ordersFBM + `?openLabel=${id}`)
        this.openNotify('success', 'common_notificationRecordCreated')
        return
      }

      if(!openLabel && noPrepay && !this.isLabel){
        this.$router.push(this.$store.getters.GET_PATHS.ordersFBM + `?noPrepay=${id}`)
        return
      }

      /**
       * if Easy order
       */
      if(this.isEasyType) {
        this.$router.push(this.$store.getters.GET_PATHS.easyOrders)
        return
      }

      this.$router.push(this.$store.getters.GET_PATHS.ordersFBM)
    },


    createLabel(id) {
      let typeDispatch = 'buyLabelFBM'

      return this.$store.dispatch(typeDispatch, id).then(response => {
        switch (this.getResponseStatus(response)) {
          /**
           * Success
           */
          case this.$store.getters.GET_ERRORS.SUCCESS_CODE: {
            return true
          }
          /**
           * Validation Error
           */
          case this.$store.getters.GET_ERRORS.VALIDATION_ERROR_CODE: {
            let errors = response.response.data.errors;
            this.notifyErrorHelper(errors)
            return false
          }
          /**
           * Undefined Error
           */
          default: {
            this.openNotify('error', 'common_notificationUndefinedError')
            return false
          }
        }
      })
    },

    generateGiftMsgFile(id, message) {
      let typeDispatch = 'generateGiftMessageFile'

      let data = {
        order_id: id,
        gift_message: message,
      }

      return this.$store.dispatch(typeDispatch, data).then(response => {
        switch (this.getResponseStatus(response)) {
            /**
             * Success
             */
          case this.$store.getters.GET_ERRORS.SUCCESS_CODE: {
            return true
          }
            /**
             * Validation Error
             */
          case this.$store.getters.GET_ERRORS.VALIDATION_ERROR_CODE: {
            let errors = response.response.data.errors;
            this.notifyErrorHelper(errors)
            return false
          }
            /**
             * Undefined Error
             */
          default: {
            this.openNotify('error', 'common_notificationUndefinedError')
            return false
          }
        }
      })
    },

    reCreateLabel(id) {
      let typeDispatch = 'reloadLabelFBM'

      return this.$store.dispatch(typeDispatch, id).then(response => {
        switch (this.getResponseStatus(response)) {
          /**
           * Success
           */
          case this.$store.getters.GET_ERRORS.SUCCESS_CODE: {
            return true
          }
          /**
           * Validation Error
           */
          case this.$store.getters.GET_ERRORS.VALIDATION_ERROR_CODE: {
            let errors = response.response.data.errors;
            this.notifyErrorHelper(errors)
            return false
          }
          /**
           * Undefined Error
           */
          default: {
            this.openNotify('error', 'common_notificationUndefinedError')
            return false
          }
        }
      })
    },

  },

}
